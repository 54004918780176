import React, { FC } from 'react';

import { IArticleCardDescription } from '@shared/types/umbraco/compositions';
import { DangerouslySetInnerHtml } from 'gatsby-theme-husky/src/layout';

const RelatedArticleCardDescription: FC<IArticleCardDescription> = ({ title }) => (
  <DangerouslySetInnerHtml element="p" className="article-card__title" html={title} />
);

export default RelatedArticleCardDescription;
